<template>
  <div>
    <Spinner v-if="fetching" />
    <div v-else class="container-fluid plr-80 payment-page">
      <div class="mb-5">
        <BookingEngineDateSelector
          :checkIn="formatDate(checkoutData.date.checkIn)"
          :checkOut="formatDate(checkoutData.date.checkOut)"
          @search="
            $router.push(
              `/listing/${$route.params.id}?checkIn=${$event.checkIn}&checkOut=${$event.checkOut}&findAvailability=true`
            )
          "
        />
      </div>

      <div class="card my-3">
        <div class="card-body px-md-5">
          <div
            class="tabs d-flex justify-content-between align-items-center py-3"
          >
            <div class="tab-section completed w-100">
              <div class="tab-icon"></div>
            </div>
            <div
              class="tab-section w-100"
              :class="{
                current: step === 'extra',
                completed: step === 'summary',
              }"
            >
              <p v-if="step === 'extra'" class="tab-label">Extra</p>
              <div class="tab-icon"></div>
            </div>
            <div
              class="tab-section w-100"
              :class="{ current: step === 'summary' }"
            >
              <p v-if="step === 'summary'" class="tab-label">Summary</p>
              <div class="tab-icon"></div>
            </div>
            <div class="tab-section">
              <div class="tab-icon"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 'extra'">
        <div class="extra-list mt-5">
          <div v-for="extra in extras" :key="extra.id" class="card my-4 extra">
            <div class="card-body row px-md-5">
              <div class="col-md-4">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <img
                      src="../assets/images/newspaper.svg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <div>{{ extra.roomName }}</div>
                </div>
              </div>
              <div class="col-md-2 my-auto">{{ extra.extraCode }}</div>
              <div class="col-md-2 my-auto" v-if="getExtraValue(extra.id)">
                <div>Quantity</div>
                <Multiselect
                  placeholder=""
                  style="width: 70px"
                  class="mt-3"
                  :options="cultswitchQtyOption(extra)"
                  :showLabels="false"
                  :searchable="false"
                  :allow-empty="false"
                  @select="extraQuantityChanged($event, extra)"
                  :value="getExtraValue(extra.id)"
                >
                </Multiselect>
              </div>
              <div class="text-main font-weight-bold col-md-2 my-auto">
                {{ extra.updatedCurrency }} {{ cultswitchProductPrice(extra) }}
              </div>
              <div class="col-md-2 text-right my-auto ml-auto">
                <button
                  v-if="!getExtraValue(extra.id)"
                  @click="addExtra(extra)"
                  class="btn btn-main px-5"
                >
                  + Add
                </button>
                <button
                  v-else
                  @click="removeExtra(extra)"
                  class="btn btn-main px-5"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="step === 'summary'">
        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="row">
              <div class="col-md-6 py-2">
                <h5 class="mb-4">Your reservation</h5>
                <table class="table custom-table">
                  <tr>
                    <td>Total number of rooms</td>
                    <td>{{ roomsCounts }}</td>
                  </tr>
                  <tr>
                    <td>Total number of persons</td>
                    <td>{{ guestsCounts }}</td>
                  </tr>
                  <tr>
                    <td>Nights</td>
                    <td>{{ nightsCount }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-md-6 py-2">
                <h5 class="mb-4">Travel Period</h5>
                <table class="table custom-table">
                  <tr>
                    <td>Check in</td>
                    <td>{{ checkoutData.date.checkIn }}</td>
                  </tr>
                  <tr>
                    <td>Check out</td>
                    <td>{{ checkoutData.date.checkOut }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <table class="table">
              <tr>
                <th>No. of rooms</th>
                <th>Total price</th>
                <th class="text-center">Persons per room</th>
                <th>Meal type</th>
                <th>Price per room</th>
                <th>Total</th>
                <th>Remove</th>
              </tr>
              <template v-for="(item, index) in checkoutData.cart">
                <tr
                  v-for="(guest, idx) in item.guests"
                  :key="`${item.id}-${idx}`"
                >
                  <td>{{ guest.guestCount }}x</td>
                  <td>
                    <span>{{ item.product.pdDesc.name }}</span
                    ><br />
                    <div class="text-main">
                      <span
                        role="button"
                        v-b-popover.bottom.hover="item.product.pdDesc.name"
                        >Rate details</span
                      >
                      | <span>Cancellation policy</span>
                    </div>
                  </td>
                  <td class="text-center">
                    <img
                      v-for="i in guest.guestCount"
                      :key="i"
                      src="../assets/icons/person.svg"
                      class="mr-1"
                      alt="person"
                    />
                  </td>
                  <td>
                    {{
                      item.product.mealsIncluded
                        ? item.product.mealsIncluded.name
                        : "No meal"
                    }}
                    included
                  </td>
                  <td>
                    <span class="text-main"
                      >{{ item.product.updatedCurrency }}
                      {{ guest.price }}</span
                    >
                  </td>
                  <td>
                    <span class="text-main"
                      >{{ item.product.updatedCurrency }}
                      {{ guest.price }}</span
                    >
                  </td>
                  <td class="text-center">
                    <img
                      @click="removeRoom(index, idx)"
                      src="../assets/icons/trash.svg"
                      class="mr-1 cursor-pointer"
                      alt="trash"
                    />
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">Your details</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="First name*"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Last name*"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Email address*"
                />
              </div>
              <div class="col-md-5 my-3 no-border">
                <vue-tel-input></vue-tel-input>
              </div>
              <div class="col-md-4 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Mandatory post code*"
                />
              </div>
              <div class="col-md-4 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="City*"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">Guest details</h5>
            <div class="row">
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="First name*"
                />
              </div>
              <div class="col-md-5 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Last name*"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="d-flex justify-content-between">
              <h5 class="mb-4">Company details (Optional)</h5>
              <div>
                <button
                  @click="showCompanyDetails = !showCompanyDetails"
                  class="btn btn-outline-main px-5"
                >
                  Open
                  <img src="../assets/icons/open.svg" class="mr-1" alt="open" />
                </button>
              </div>
            </div>
            <div class="row" v-if="showCompanyDetails">
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Company name"
                />
              </div>
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Address 1"
                />
              </div>
              <div class="col-md-10 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Address 2 (Optional)"
                />
              </div>
              <div class="col-md-3 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="City"
                />
              </div>
              <div class="col-md-3 my-3">
                <input
                  class="form-control no-border"
                  type="text"
                  placeholder="Mandatory post code"
                />
              </div>
              <div class="col-md-3 my-3">
                <select class="form-control no-border" placeholder="Country">
                  <option value="Nigeria">Nigeria</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <div class="d-flex justify-content-between">
              <h5 class="mb-4">Special requests (Optional)</h5>
              <div>
                <button
                  @click="showSpecialRequest = !showSpecialRequest"
                  class="btn btn-outline-main px-5"
                >
                  Open
                  <img src="../assets/icons/open.svg" class="mr-1" alt="open" />
                </button>
              </div>
            </div>
            <div class="row" v-if="showSpecialRequest">
              <div class="col-md-10 my-3">
                <textarea
                  class="form-control no-border"
                  placeholder="Do you have any special requests / wishes?"
                  rows="7"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="card my-4">
          <div class="card-body px-md-5 shadow-sm">
            <h5 class="mb-4">Credit card details</h5>
            <p>
              Your credit card is only required to guarantee your reservation.
              Payment is made directly at the accommodation.
            </p>
            <div class="row">
              <div class="col-md-9 my-3">
                <div class="row">
                  <div class="col-md-6 my-3">
                    <select class="form-control no-border light-bg">
                      <option value="">Choose your card type</option>
                    </select>
                  </div>
                  <div class="col-md-6 my-3">
                    <input
                      class="form-control no-border light-bg"
                      type="text"
                      placeholder="****_****_****_****"
                    />
                  </div>
                  <div class="col-md-3 my-3">
                    <select class="form-control no-border light-bg">
                      <option value="">Year</option>
                    </select>
                  </div>
                  <div class="col-md-3 my-3">
                    <select class="form-control no-border light-bg">
                      <option value="">Month</option>
                    </select>
                  </div>
                  <div class="col-md-12 col-offset-6 my-3">
                    <input
                      class="form-control no-border light-bg"
                      type="text"
                      placeholder="Name on card"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3 px-3">
                <img
                  src="../assets/images/pci-booking.svg"
                  class="img-fluid"
                  alt="PCI-Booking"
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="my-1">
          <input type="checkbox" class="mr-1" />
          With this booking I confirm the
          <a href="#" class="text-main">Terms & Conditions</a> and the
          <a href="#" class="text-main">Privacy Policy (GDPR)</a>
        </div>
      </div>

      <BookingCheckout
        class="my-5"
        :type="checkoutData.distributorType"
        :cart="checkoutData.cart"
        :extras="checkoutData.extras"
        :sticky="false"
        :buttonText="step === 'extra' ? 'Summary' : 'Book Now'"
        @proceed="proceedWithBooking"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Spinner from "./Widgets/Spinner.vue";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import BookingCheckout from "./Widgets/BookingCheckout.vue";
import BookingEngineDateSelector from "./Widgets/BookingEngineDateSelector.vue";
export default {
  name: "Reservation",
  components: {
    Spinner,
    Multiselect,
    BookingCheckout,
    BookingEngineDateSelector,
  },
  data() {
    return {
      fetching: false,
      step: "extra",
      showCompanyDetails: false,
      showSpecialRequest: false,
      checkoutData: null,
      extras: [],
    };
  },
  async created() {
    this.fetching = true;
    const { id } = this.$route.params;
    let checkoutData = sessionStorage.getItem(`checkoutData-${id}`);
    checkoutData = JSON.parse(checkoutData) || null;
    if (
      !checkoutData ||
      moment(checkoutData.date.checkIn).isBefore(
        moment(new Date()).format("YYYY-MM-DD")
      )
    ) {
      this.$router.push(`/listing/${id}`);
    }
    this.checkoutData = checkoutData;

    const { data } = await this.findCultswitchAvailability({
      check_in: checkoutData.date.checkIn,
      check_out: checkoutData.date.checkOut,
      property_id: id,
    });
    this.extras = data?.data?.extras || [];
    this.fetching = false;
  },
  computed: {
    roomsCounts() {
      return this.checkoutData.cart.reduce((accumulator, item) => {
        return accumulator + item.guests.length;
      }, 0);
    },
    guestsCounts() {
      let guests = 0;
      this.checkoutData.cart.forEach((item) => {
        item.guests.forEach(({ guestCount }) => (guests += guestCount));
      });
      return guests;
    },
    nightsCount() {
      const { checkIn, checkOut } = this.checkoutData.date;
      return moment(checkOut, "YYYY-MM-DD").diff(
        moment(checkIn, "YYYY-MM-DD"),
        "days"
      );
    },
  },
  methods: {
    ...mapActions("property", ["findCultswitchAvailability"]),
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    proceedWithBooking() {
      if (this.step === "extra") {
        const { id } = this.$route.params;
        sessionStorage.setItem(
          `checkoutData-${id}`,
          JSON.stringify(this.checkoutData)
        );
        this.step = "summary";
      } else if (this.step === "summary") {
        this.$router.push(`/reservation_success`);
      }
    },
    cultswitchProductPrice(product, guests = 1) {
      const prices = Object.values(product.total);
      if (product.total[guests.toString()]) {
        return product.total[guests.toString()].price;
      }
      return prices.length ? prices[0].price : 0;
    },
    cultswitchQtyOption(extra) {
      return Array.from({ length: extra.numberOfUnits + 1 }, (_, i) => i);
    },
    addExtra(extra, quantity = 1) {
      const idx = this.checkoutData.extras.findIndex(
        ({ id }) => extra.id === id
      );
      if (idx < 0) {
        this.checkoutData.extras.push({
          id: extra.id,
          quantity,
          price: this.cultswitchProductPrice(extra),
          extra,
        });
      } else {
        this.checkoutData.extras[idx]["quantity"] = quantity;
        this.checkoutData.extras[idx]["price"] = this.cultswitchProductPrice(
          extra,
          quantity
        );
      }
    },
    removeExtra(extra) {
      const idx = this.checkoutData.extras.findIndex(
        ({ id }) => extra.id === id
      );
      this.checkoutData.extras.splice(idx, 1);
    },
    getExtraValue(id) {
      const item = this.checkoutData.extras.find((extra) => extra.id === id);
      return item ? item.quantity : 0;
    },
    extraQuantityChanged(qty, extra) {
      this.addExtra(extra, qty);
    },
    removeRoom(cartIndex, guestIdx) {
      this.checkoutData.cart[cartIndex].guests.splice(guestIdx, 1);
      if (!this.checkoutData.cart[cartIndex].guests.length) {
        this.checkoutData.cart.splice(cartIndex, 1);
      }
      const { id } = this.$route.params;
      sessionStorage.setItem(
        `checkoutData-${id}`,
        JSON.stringify(this.checkoutData)
      );
    },
  },
};
</script>
